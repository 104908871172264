import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/tutorials.tsx";
export const pageTitle = "Implementing DELETE operation";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TutorialSteps = makeShortcode("TutorialSteps");
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "implementing-delete-operation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#implementing-delete-operation",
        "aria-label": "implementing delete operation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Implementing DELETE operation`}</h1>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#what-you-need"
        }}>{`What you need`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#1-implement-server-side"
        }}>{`1. Implement server-side`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#2-implement-client-side"
        }}>{`2. Implement client-side`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#3-test-deleting-a-blog-post"
        }}>{`3. Test deleting a blog post`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#4-test-an-error-case"
        }}>{`4. Test an error case`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#5-add-the-documentation-service"
        }}>{`5. Add the Documentation service`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#6-check-the-docservice-page"
        }}>{`6. Check the DocService page`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#whats-next"
        }}>{`What's next`}</a></li>
    </ul>
    <p>{`So far, we created, read, and updated a blog post.
Now, let's implement and make a call to delete a blog post.
Also, we'll add Armeria's `}<a parentName="p" {...{
        "href": "/docs/server-docservice"
      }}>{`Documentation Service`}</a>{` for testing our blog service.`}</p>
    <TutorialSteps current={6} mdxType="TutorialSteps" />
    <h2 {...{
      "id": "what-you-need",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#what-you-need",
        "aria-label": "what you need permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What you need`}</h2>
    <p>{`You need to have the following files obtained from previous steps.
You can always `}<a parentName="p" {...{
        "href": "https://github.com/line/armeria-examples/tree/main/tutorials/thrift"
      }}>{`download`}</a>{` the full version, instead of creating one yourself.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/thrift/blog/define-service#3-compile-the-thrift-file"
        }}>{`Generated Java code`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`BlogServiceImpl.java`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`Main.java`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`BlogClient.java`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`BlogServiceTest.java`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`BlogServiceExceptionHandler.java`}</inlineCode></li>
    </ul>
    <h2 {...{
      "id": "1-implement-server-side",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#1-implement-server-side",
        "aria-label": "1 implement server side permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1. Implement server-side`}</h2>
    <p>{`In the `}<inlineCode parentName="p">{`BlogServiceImpl`}</inlineCode>{` class, implement the `}<inlineCode parentName="p">{`deleteBlogPost()`}</inlineCode>{` method to delete a blog post.
Let's throw an exception in case there is no blog post for the given ID.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java",
        "metastring": "filename=BlogServiceImpl.java",
        "filename": "BlogServiceImpl.java"
      }}>{`@Override
public void deleteBlogPost(DeleteBlogPostRequest request, AsyncMethodCallback<Void> resultHandler)
      throws TException {
  final BlogPost removed = blogPosts.remove(request.getId());
  if (removed == null) {
    resultHandler.onError(
            new IllegalArgumentException("The blog post does not exist. ID: " + request.getId()));
  } else {
    resultHandler.onComplete(null);
  }
}
`}</code></pre>
    <h2 {...{
      "id": "2-implement-client-side",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#2-implement-client-side",
        "aria-label": "2 implement client side permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`2. Implement client-side`}</h2>
    <p>{`In the `}<inlineCode parentName="p">{`BlogClient`}</inlineCode>{` class, add the `}<inlineCode parentName="p">{`deleteBlogPost()`}</inlineCode>{` method to send a request deleting a blog post.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java",
        "metastring": "filename=BlogClient.java",
        "filename": "BlogClient.java"
      }}>{`import example.armeria.blog.thrift.DeleteBlogPostRequest;
...
void deleteBlogPost(int id) throws TException {
  final DeleteBlogPostRequest request = new DeleteBlogPostRequest().setId(id);
  blogService.deleteBlogPost(request);
}
`}</code></pre>
    <h2 {...{
      "id": "3-test-deleting-a-blog-post",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#3-test-deleting-a-blog-post",
        "aria-label": "3 test deleting a blog post permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`3. Test deleting a blog post`}</h2>
    <p>{`Let's test deleting a blog post.
We'll delete the blog post with ID `}<inlineCode parentName="p">{`1`}</inlineCode>{`, and try retrieving with the same ID to verify it is indeed deleted.
Add a test method like the following.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java",
        "metastring": "filename=BlogServiceTest.java",
        "filename": "BlogServiceTest.java"
      }}>{`@Test
@Order(7)
void deleteBlogPost() throws TException {
  final BlogClient client = new BlogClient(server.httpUri(), "/thrift");
  client.deleteBlogPost(1);
  final Throwable exception = catchThrowable(() -> {
    client.getBlogPost(1);
  });
  assertThat(exception)
    .isInstanceOf(BlogNotFoundException.class)
    .extracting("reason")
    .asString()
    .isEqualTo("The blog post does not exist. ID: 1");
}
`}</code></pre>
    <p>{`Run all the test cases on your IDE or using Gradle.
Check that you see the test is passed.`}</p>
    <h2 {...{
      "id": "4-test-an-error-case",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#4-test-an-error-case",
        "aria-label": "4 test an error case permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`4. Test an error case`}</h2>
    <p>{`Let's test deleting a blog post that does not exist.
Add a test method like the following.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java",
        "metastring": "filename=BlogServiceTest.java",
        "filename": "BlogServiceTest.java"
      }}>{`@Test
@Order(8)
void deleteInvalidBlogPost() {
  final BlogClient client = new BlogClient(server.httpUri(), "/thrift");
  final Throwable exception = catchThrowable(() -> {
    client.deleteBlogPost(100);
  });
  assertThat(exception)
    .isInstanceOf(BlogNotFoundException.class)
    .extracting("reason")
    .asString()
    .isEqualTo("The blog post does not exist. ID: 100");
}
`}</code></pre>
    <p>{`Run all the test cases on your IDE or using Gradle.
Check that you see the test is passed.`}</p>
    <h2 {...{
      "id": "5-add-the-documentation-service",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#5-add-the-documentation-service",
        "aria-label": "5 add the documentation service permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`5. Add the Documentation service`}</h2>
    <p>{`This time, we'll add Armeria's `}<a parentName="p" {...{
        "href": "/docs/server-docservice"
      }}>{`Documentation service`}</a>{`.
The Documentation service automatically creates documentation of your service methods, as well as providing means to test out the methods.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`In the `}<inlineCode parentName="p">{`newServer()`}</inlineCode>{` method, add a `}<a parentName="p" {...{
            "href": "type://DocService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/DocService.html"
          }}>{`type://DocService`}</a>{` and a request example for `}<a parentName="p" {...{
            "href": "/tutorials/thrift/blog/implement-create"
          }}>{`creating blog posts`}</a>{`,
using `}<a parentName="p" {...{
            "href": "type://DocServiceBuilder#exampleRequests(Class,String,Iterable):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/DocServiceBuilder.html#exampleRequests(java.lang.Class,java.lang.String,java.lang.Iterable)"
          }}>{`type://DocServiceBuilder#exampleRequests(Class,String,Iterable)`}</a>{`. Feel free to add more examples for other service methods.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=Main.java",
            "filename": "Main.java"
          }}>{`import com.linecorp.armeria.server.docs.DocService;
import example.armeria.blog.thrift.CreateBlogPostRequest;
...
private static Server newServer(int port) throws Exception {
  ...
  final CreateBlogPostRequest exampleRequest = new CreateBlogPostRequest()
          .setTitle("Example title")
          .setContent("Example content");
  final DocService docService = DocService
          .builder()
          .exampleRequests(List.of(new BlogService.createBlogPost_args(exampleRequest)))
          .build();
  ...
}
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`In the `}<inlineCode parentName="p">{`newServer()`}</inlineCode>{` method, add the `}<a parentName="p" {...{
            "href": "type://DocService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/DocService.html"
          }}>{`type://DocService`}</a>{` to our server builder.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=Main.java highlight=5",
            "filename": "Main.java",
            "highlight": "5"
          }}>{`private static Server newServer(int port) throws Exception {
  ...
  return Server.builder()
           .http(port)
           .service("/thrift", tHttpService)
           // You can access the documentation service at http://127.0.0.1:8080/docs.
           // See https://armeria.dev/docs/server-docservice for more information.
           .serviceUnder("/docs", docService)
           .build();
}
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`(Optional) To access the Documentation service result easily, edit the log message in the `}<inlineCode parentName="p">{`main()`}</inlineCode>{` method.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=Main.java highlight=2",
            "filename": "Main.java",
            "highlight": "2"
          }}>{`public static void main(String[] args) throws Exception {
  ...
  logger.info("Server has been started. Serving DocService at http://127.0.0.1:{}/docs",
              server.activeLocalPort());
}
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Now, `}<a parentName="p" {...{
            "href": "/tutorials/thrift/blog/run-service#4-run-the-server"
          }}>{`re-run the server`}</a>{`.`}</p>
        <p parentName="li">{`The server and services are launched successfully if you see this message.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-bash"
          }}>{` Server has been started. Serving DocService at http://127.0.0.1:8080/docs
`}</code></pre>
      </li>
    </ol>
    <h2 {...{
      "id": "6-check-the-docservice-page",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#6-check-the-docservice-page",
        "aria-label": "6 check the docservice page permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`6. Check the DocService page`}</h2>
    <p>{`Let's test and call our service operations using Armeria's Documentation service.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Click the URL `}<a parentName="p" {...{
            "href": "http://127.0.0.1:8080/docs"
          }}>{`http://127.0.0.1:8080/docs`}</a>{` from the log message or open up the URL on a web browser.`}</p>
        <p parentName="li">{`If you see the Document service page, you've successfully launched the `}<a parentName="p" {...{
            "href": "type://DocService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/DocService.html"
          }}>{`type://DocService`}</a>{` and server.`}</p>
        <p parentName="li"><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "650px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "53.37423312883436%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAABdklEQVQoz52RTU/CQBCG97fpTYwnOQlBE48G/XUkqIlgYuLBE8EP9CDfUKh0t7ul7bb0NbPQysdFneTJTuededvZsqPSDXKFKg6LVRycVJErXGPvuIL9fAXFizucXd3jlLiso1SuoVSur6htQHr+/Bbs7X2I/tBFt8/x2hqg8dxBo7mk+fJD62OIdvcLnx3b0OnNdiCd6cBDGkoJjMcDjEd9TKwRLGsIyxqZfDodw7YtCD5DGMyRJNEOSGKwKZeIoghaR+BcgAsBx+Hwfd/UwlBnZxAE0FojimJz6tVcCvWwx64DzxWQUsHzPNMYxzEWi4UxoKB6GIYmD4LQQDGfz41GvRQ0x556DpQrMpFMaJhEypMkgVJqZZ7A82L4fmxy2oK2I0PqywzpC2mABs0qWhvT9FxeSVpbkuppPYU9tGdQgkMqBSnlTsP68zLXq3vdrKew1kQgWcTZitsmf4W5ziz7Edurrr/5tzBXSti2Dc75zpr/MfwGQPA3z5N5pj4AAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "tutorial blogservice thrift docservice start",
              "title": "tutorial blogservice thrift docservice start",
              "src": "/static/cfcd1affbab0a61c9b8426592b188eba/a6d36/tutorial_blogservice_thrift_docservice_start.png",
              "srcSet": ["/static/cfcd1affbab0a61c9b8426592b188eba/222b7/tutorial_blogservice_thrift_docservice_start.png 163w", "/static/cfcd1affbab0a61c9b8426592b188eba/ff46a/tutorial_blogservice_thrift_docservice_start.png 325w", "/static/cfcd1affbab0a61c9b8426592b188eba/a6d36/tutorial_blogservice_thrift_docservice_start.png 650w", "/static/cfcd1affbab0a61c9b8426592b188eba/e548f/tutorial_blogservice_thrift_docservice_start.png 975w", "/static/cfcd1affbab0a61c9b8426592b188eba/d073d/tutorial_blogservice_thrift_docservice_start.png 1297w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Click the `}<strong parentName="p">{`createBlogPost()`}</strong>{` method link in the left panel. You can make calls to the method by clicking on the `}<inlineCode parentName="p">{`Debug`}</inlineCode>{` button on the top right.`}</p>
        <p parentName="li"><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "500px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "92.02453987730061%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAAAsTAAALEwEAmpwYAAABZ0lEQVQ4y61UbU7CQBDtNbkBHsBz6D9j/ONB9I83QNIgXwWBog1ItdCFdnfmmdmyBFFKBCZ5nUl35+282Q+PiUCvE1DnHRTOrOdMgwEw87/hQYy4AK/9CeYppdDutBGOx+j3+wh6PQyHQwRBgNFohCzLYIwphdYay+WyICQim5TnufUuXq1Wm9glCcqIrWQJJNmZ68XRkiVZVhaZvu8jiiJI1XEcI0kSpGlqKxVJThZKNsxz1Uhis9m0fRQi6W2j0UCtVkO327X9bLVadkFH/JcaSygTXGOFSCoSk3+ykEBiGXPxXsLtHdq2Q33cN+4d6sk+lErenXTSwT4n2Q/J5zLvmAeg9HGQDxGD1h58ZskMhmaC2QW48Ifucvi2wNXtM27ufVzf1TEexMBCQc8VTKJgvtLCfyQw8QIm1zD0m0yuqyV8ac9QqT6gevmEysUjOvUImCvQdA6eJODoEyzxYAoOZ4CmUsnf+KiC+Y5kxacAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "tutorial blogservice thrift reqex",
              "title": "tutorial blogservice thrift reqex",
              "src": "/static/6d7acf3fa7d957c6375e0f63998699bc/0b533/tutorial_blogservice_thrift_reqex.png",
              "srcSet": ["/static/6d7acf3fa7d957c6375e0f63998699bc/222b7/tutorial_blogservice_thrift_reqex.png 163w", "/static/6d7acf3fa7d957c6375e0f63998699bc/ff46a/tutorial_blogservice_thrift_reqex.png 325w", "/static/6d7acf3fa7d957c6375e0f63998699bc/0b533/tutorial_blogservice_thrift_reqex.png 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span></p>
        <p parentName="li">{`Note that in the `}<strong parentName="p">{`REQUEST BODY`}</strong>{` section the values specified in the `}<inlineCode parentName="p">{`exampleRequest`}</inlineCode>{` are automatically displayed on the page.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=Main.java",
            "filename": "Main.java"
          }}>{`final CreateBlogPostRequest exampleRequest = new CreateBlogPostRequest()
        .setTitle("Example title")
        .setContent("Example content");
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Click the `}<strong parentName="p">{`SUBMIT`}</strong>{` button, and you'll see the blog post information returned in the right panel.`}</p>
        <p parentName="li"><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "650px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "38.036809815950924%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsTAAALEwEAmpwYAAABB0lEQVQoz4VSa0vEMBDM//91fvBUDg4sd/W0GtKkafPazUja6+Oq4oSBBLKzM+wK02lskfNyG0+B9wFSSjjnsEdeC0aIQ+3wWFk8nAxe6g6nxuK5inh6JRwvhKZnuEiIIYESIxGhdwEuxEVsKyremojzNaJ6JzSaoD1DDRNbx+M7cYaRAbYLaJSB7gbYwYOZF8FZVEDbkgn/wbYR1oTR4W+RZ4/C+9Ipg4hBzGPXLVOisajENWqKansHHwLoJj6J3hzylwErC875h9jM8rk0/awHaOlx/mhxqCSOFwUf6S62mIv209rHokQYbILvCcQZeiConpDofjCi2C7cTmzLgtXpulJ/rc43kU90TxCtzukAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "tutorial blogservice thrift return",
              "title": "tutorial blogservice thrift return",
              "src": "/static/2c7f487fa0d780fa5a42e91d24af7dbb/a6d36/tutorial_blogservice_thrift_return.png",
              "srcSet": ["/static/2c7f487fa0d780fa5a42e91d24af7dbb/222b7/tutorial_blogservice_thrift_return.png 163w", "/static/2c7f487fa0d780fa5a42e91d24af7dbb/ff46a/tutorial_blogservice_thrift_return.png 325w", "/static/2c7f487fa0d780fa5a42e91d24af7dbb/a6d36/tutorial_blogservice_thrift_return.png 650w", "/static/2c7f487fa0d780fa5a42e91d24af7dbb/d7abb/tutorial_blogservice_thrift_return.png 959w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span></p>
      </li>
    </ol>
    <h2 {...{
      "id": "whats-next",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#whats-next",
        "aria-label": "whats next permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What's next`}</h2>
    <p>{`In this step, we've implemented a service method and client method for deleting a blog post.
We've also added `}<a parentName="p" {...{
        "href": "/docs/server-docservice"
      }}>{`Documentation service`}</a>{` to our server.`}</p>
    <p>{`We've finally come to the end of this tutorial.
Next, try adding more service methods to the tutorial or have a go at developing a service of your own.`}</p>
    <TutorialSteps current={6} mdxType="TutorialSteps" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      